import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import '../ServiceCard/ServiceCard.scss';

const ServiceCard = (props) => {

  const { key,serviceImage,slug,serviceTitle} = props;
  var Image = getImage(serviceImage)
  var servicePath = `/our-services/${slug}/`
  return (
     <>
      <div className="service-wrapper">
        <Link to={`${servicePath}`} >
          
              <GatsbyImage image={Image}  />
             <p className="service-card-title">{props.serviceTitle}</p>
            
        </Link>
      </div>
       
   </>
  )
}

export default ServiceCard
